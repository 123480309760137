.App {
  font-family: sans-serif;
  text-align: center;
}

html, body, footer {
  background-color: rgb(51,51,51) !important;
}

header {
  border-bottom: 1px solid #fff;
}
img {
  width: 100%;
}

a {
  color: #92D050;
}

.slick-dots {
  position: relative;
  bottom: -5px;
}
.slick-dots li {
  width: 50px;
}
.slick-dots li svg {
  transform: scale(2.5, 1.5);
}
.slick-dots li.slick-active {
  color: #92D050;
}

.youtube_wrapper {
  position: relative;
  width: 100%;
}

.youtube_wrapper:before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

.youtube_iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
